import "./App.css";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "../src/CSS/custom.css";
import Login from "./pages/Login";
import AdminDashboard from "./pages/AdminDashboard";
import CancelOrder from "./admin/Cancel/CancelOrder";
import Stores from "./pages/Stores";
import Items from "./pages/Items";
import { useEffect, useState } from "react";
import { acceptOrder, getorderlist } from "./API";
import { ToastContainer } from "react-toastify";
import NewOrder from "./components/Popups/NewOrder";
import MultipleOrder from "./components/Popups/MultipleOrder";
import Reports from "./pages/Reports";

function App() {
  const [open, setOpen] = useState(false);
  const [opentwo, setOpentwo] = useState(false);
  let confirmarr = [];
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpentwo = () => setOpentwo(true);
  const handleClosetwo = () => setOpentwo(false);
  const [confirmOrder, setConfirmOrder] = useState();
  const [canel, setcancel] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [acceptorderbutton, setacceptorderbutton] = useState(true);
  useEffect(() => {
    if (token) {
      navigate("/admindashboard");
      // console.log("TOKEN", token);
    }
  }, []);

  useEffect(() => {
    // console.log(token)
    if (token === null || token === "null" || token === undefined || !token) {
      // console.log("token inside order list", token)
      return;
    } else {
      // console.log("token not inside order list", token)
      let orderListIntervalId = setInterval(() => {
        getorderlist(10, 1, token)
          .then((res) => {
            //  console.log('Order list confirm response', res)
            setConfirmOrder(res.data.order);
          })
          .catch((err) => {
            console.log("Error in Confirm Order", err);
          });
      }, 1500);
      return () => {
        clearInterval(orderListIntervalId);
      };
    }
  }, [token]);

  useEffect(() => {
    confirmOrder?.map((item) => {
      if (item.status === "order confirmed") {
        //  ordercount=ordercount+1

        return confirmarr.push(item);
      }
      return null;
    });
    setcancel(true);

    //  console.log('confirmarr',confirmarr)
    if (confirmarr?.length === 1 && canel) {
      confirmarr?.map((item) => {
        if (
          localStorage.getItem("confirmOrderId") !== item.orderId &&
          item.storeId !== "SMASH BURGER"
        ) {
          localStorage.setItem("confirmOrderId", item.orderId);
          handleOpen();
        }
        return null;
      });
    } else if (confirmarr?.length > 1 && canel) {
      // console.log('confirmarr[confirmarr?.length-1].orderId',confirmarr[0].orderId)
      if (
        confirmarr[0].orderId !==
          localStorage.getItem("confirmOrderIdmultiple") &&
        confirmarr[0].storeId !== "SMASH BURGER"
      ) {
        localStorage.setItem("confirmOrderIdmultiple", confirmarr[0].orderId);
        handleOpentwo();
      }
    }
  }, [confirmarr, confirmOrder]);

  const location = useLocation();

  const handleacceptorder = (confirmOrder) => {
    // console.log('confirmOrder',confirmOrder)
    //   placeorder(confirmOrder[0]).then((res)=>{
    //     toastmsg(res.data.message)
    //     // props.refreshfunction()
    //     navigate('/admindashboard')
    //     handleClose()
    //  })
    //  .catch((err)=>{
    //     console.log('Accept Order Error', err)
    //   })
    if (acceptorderbutton) {
      acceptOrder(confirmOrder[0]?.storeId, confirmOrder[0]?.orderId)
        .then((res) => {
          // console.log('Accept Order Response',res)
          setacceptorderbutton(false);
        })
        .catch((err) => {
          console.log("Accept Order Error", err);
        });
    }
  };
  useEffect(() => {
    setInterval(() => {
      setacceptorderbutton(true);
    }, 7000);
  }, []);
  return (
    <div className="App">
      {/* {console.log('confirmOrder',confirmOrder)}  */}
      {location.pathname === "/" ? null : (
        <>
          <NewOrder
            open={open}
            handleacceptorder={handleacceptorder}
            handleClose={handleClose}
            confirmOrder={confirmOrder}
            confirmarr={confirmarr}
            handleClosetwo={handleClosetwo}
          />
          <MultipleOrder
            opentwo={opentwo}
            handleClosetwo={handleClosetwo}
            confirmOrder={confirmOrder}
          />
        </>
      )}

      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admindashboard"
          element={
            <AdminDashboard
              canel={canel}
              setcancel={setcancel}
              handleClosetwo={handleClosetwo}
              confirmOrder={confirmOrder}
            />
          }
        />
        <Route path="/cancelorder" element={<CancelOrder />} />
        <Route
          path="/adminstores"
          element={<Stores confirmOrder={confirmOrder} />}
        />
        <Route
          path="/reports"
          element={<Reports confirmOrder={confirmOrder} />}
        />
        <Route path="items/:storename" element={<Items />} />
        {/* <Route path="zones/:id" element={<SingleZone/>}/> */}
        {/* <Route path="editzone/:id" element={<EditZones/>}/>  */}
        {/* <Route path="adminstores/:id" element={<IndividualStores/>}/> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
